<template>
  <div class="newList" style="padding-bottom: 0;">
<!--    <div class="topbananr">-->
<!--      <img src="../../assets/images/shouye_02.jpg" alt="" style="width:100%">-->
<!--    </div>-->
    <h3 class="t">新员工培训之困</h3>
    <div class="faContent">
      <div class="fiveB">
        <div class="content">
          <div class="box" v-for="(list) in fiveList" >
<!--            <p class="title">{{ list.title }}</p>-->
            <span>{{ list.info }}</span>
<!--            <span class="btnimg"><img src="../../assets/images/zx.png" alt=""></span>-->
          </div>
        </div>
<!--        <div class="content">-->
<!--          <div class="box" v-for="(list, index) in fiveList" v-if="index >=3 ">-->
<!--            <p class="title">{{ list.title }}</p>-->
<!--            <span>{{ list.info }}</span>-->
<!--            <span class="btnimg"><img src="../../assets/images/zx.png" alt=""></span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <h3 class="t">新员工人才发展解决方案</h3>
      <div class="faFoot">
        <div class="content">
          <el-tabs tab-position="left" style="height: 230px;">
            <el-tab-pane :label="list.title" v-for="list in tabs">
              <div class="threeBox" v-for="ele in tabsBoxList[list.data]">
                <p class="title"><i class="el-icon-check"></i>{{ ele.title }}</p>
                <span class="info">{{ ele.info }}</span>
                <p class="fBtn">
<!--                  <el-button type="primary" size="mini">查看详情</el-button>-->
                  <el-button type="warning" size="mini" plain @click="$refs.modalBoxPage.dialogVisible = true" style="background-color: #E6A23C;border-color: #E6A23C; color: #FFFFFF">获取解决方案</el-button>
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <h3 class="t">课程收益</h3>
    <div class="threeBox mb-50">
      <el-row  :gutter="20">
        <el-col :span="8" v-for="list in threeBoxList" class="info">
          <div class="body">
            <img :src="list.icon" alt="" class="icon">
            <h3 style="border-bottom: #0b0b0b 1px solid; padding-bottom: 15px;">{{list.title}}</h3>
            <p v-html="list.value" style="margin-top: 20px; color: #333333;"></p>
<!--            <el-button type="primary" size="mini" @click="$refs.modalBoxPage.dialogVisible = true" class="btn__" style="background-color: #E6A23C;border-color: #E6A23C;">免费获取解决方案</el-button>-->
          </div>
        </el-col>
      </el-row>
    </div>
    <modalBox ref="modalBoxPage" />
  </div>
</template>

<script>
// import ClickModel from '../../components/ClickModel.vue';
import modalBox from '../index/components/modal.vue';
  export default {
  name: 'facingProblemsPage',
  components:{modalBox},
  data() {
    return {
      fiveList: [
        { info: "培训没有针对性，培养目标（达到什么标准）不够明确" },
        { info: "缺乏互动与反馈，不知道员工要什么，不知道要改进什么" },
        { info: "先“填鸭”后“放羊”，短时间灌输过多，无法吸收；培训结束后，缺乏持续跟踪，导致员工无法顺利上手完成工作任务" },
        { info: "培训部门孤独推动，没有其他部门配合，员工需求定位、工作转化、带教辅导、评价反馈缺少支持" },
        { info: "Z时代新员工呈现个性鲜明，难管理、易流失" },
      ],
      tabs: [
        { title: "阶段1：适应、融入", data:'one' },
        { title: "阶段2：胜任、发展", data:'two' },
        // { title: "精益成本" },
        // {title:"精益制造"}
      ],
      tabsBoxList: {
        one:[
          { title: "适应", info: "学习职业技能、培养关键素养，助新员工适应职场角色转变。" },
          { title: "融入", info: "学习企业文化、业务知识，从心认知、组织关系上融入企业。" },
        ],
        two:[
          { title: "胜任", info: "岗位带教辅助新员工胜任工作任务，建立良好工作模式。" },
          { title: "发展", info: "个人发展和组织发展需求结合，建立员工发展规划和个人提升计划。" }
        ]
      },
      threeBoxList:[
        {value:"各个岗位工作实践，实用，接地气，能够有效地激活学员的参与，营造学习氛围。", title:"激活新员工学习氛围", icon:require("@/assets/images/icon1.png")},
        {value:"讲解企业文化如何凝聚人心，让企业文化和岗位职责有机融合。", title:"企业文化共创和落地", icon:require("@/assets/images/icon2.png")},
        {value:"帮助员工建立清晰的个人成长路径，实现个人发展和组织发展的双向融合。", title:"稳定新员工归属感", icon:require("@/assets/images/icon3.png")},
      ],
    }
  },
  methods: {

    }
  }
</script>

<style lang="scss" scoped>
.mb-50{
  margin-bottom: 50px;
}
</style>
